import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

const bufferToBase64URLString = (buffer: ArrayBuffer): string => {
  let base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)))
  .replace(/\+/g, '-')
  .replace(/\//g, '_');
  const paddingIndex = base64String.indexOf('=');
  return paddingIndex > -1 ? base64String.substring(0, paddingIndex) : base64String;
};
export const base64URLStringToBuffer = (base64URLString: string): Uint8Array => {
  const padding = '==='.slice(0, (4 - (base64URLString.length % 4)) % 4);
  const base64 = (base64URLString + padding).replace(/-/g, '+').replace(/_/g, '/');
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
};
// Customizable Area End

interface CustomAuthenticatorResponse extends AuthenticatorResponse {
  attestationObject: ArrayBuffer;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start 
  successMessage: string;
  errorMessage: string;
  token:any;
   // Customizable Area End
}

interface SS {
  id: any;
}

export default class FingerprintPaymentAuthentication2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start 
  btnExampleProps = {
    onPress: () => {
        this.setState({ txtSavedValue: 'A' });
      }
  }
  btnShowHideImageProps = {}
  btnShowHideProps ={}
  txtInputProps = {}
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    
    this.subScribedMessages = [      
      getName(MessageEnum.AccoutLoginSuccess),

     // Customizable Area Start 
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      // Customizable Area Start
      successMessage: "",
      errorMessage: "",
      token:null,
      // Customizable Area End
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 // Customizable Area Start
  async getUserToken() {
    const token = await getStorageData("userToken"); 
    if (!token) {
      this.showError("Token not found in storage");
      return;
    }
    this.setState({ token });
    this.send(new Message(getName(MessageEnum.RestAPIRequestMessage)));
  }
  // Customizable Area End
  
  // Customizable Area Start
  handleAPIResponse = (message: Message) => {
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseData?.success) {
        this.showMessage('Biometric registration successful!', 'success');
    } else {
        this.showMessage(responseData?.error || 'Verification failed', 'error');
    }
};

  
  handleBeginRegistration = async (type: string): Promise<void> => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token, 
    };
  
    const optionsRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    optionsRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    optionsRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    
    optionsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/bx_block_fingerprintpaymentauthentication2/biometric_authentication/options_for_registration"
    );
  
    runEngine.sendMessage(this.props.id, optionsRequestMessage);
    console.log('optionsRequestMessage:', optionsRequestMessage);
  };
  
  beginCredentialCreation = async (options: any) => {
    try {
      options.challenge = base64URLStringToBuffer(options.challenge);
      options.user.id = base64URLStringToBuffer(options.user.id);
  
      const publicKeyCredential = (await navigator.credentials.create({
        publicKey: options,
      })) as PublicKeyCredential & { response: CustomAuthenticatorResponse };
  
      const payload = {
        type: 'public-key',
        id: publicKeyCredential.id,
        rawId: bufferToBase64URLString(publicKeyCredential.rawId),
        authenticatorAttachment: 'cross-platform',
        response: {
          clientDataJSON: bufferToBase64URLString(publicKeyCredential.response.clientDataJSON),
          attestationObject: bufferToBase64URLString(publicKeyCredential.response.attestationObject),
          transports: ['hybrid', 'internal'],
        },
        clientExtensionResults: {},
      };
  
     
      const registrationMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      
      const header = {
        "Content-Type": "application/json",
        "token": this.state.token, 
      };
  
      registrationMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      registrationMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      registrationMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
  
      registrationMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/bx_block_fingerprintpaymentauthentication2/biometric_authentication/register"
      );
  
      runEngine.sendMessage(this.props.id, registrationMessage);
      console.log('registrationMessage:', registrationMessage);
  
    } catch (error: any) {
      this.showError(`Credential creation failed: ${error.message}`);
    }
  };
  
  showMessage = (message: string, type: 'success' | 'error') => {
    const alertMessage = new Message(getName(MessageEnum.AlertMessage));
    alertMessage.addData(getName(MessageEnum.AlertMessage), message);
    
    if (type === 'success') {
        alertMessage.addData(getName(MessageEnum.AlertTypeMessage), 'success');
    }
    
    runEngine.sendMessage(this.props.id, alertMessage);
};

showError = (message: string) => {
  const errorMessage = new Message(getName(MessageEnum.AlertMessage));
  errorMessage.addData(getName(MessageEnum.AlertMessage), message);
  runEngine.sendMessage(this.props.id, errorMessage);
};
  // Customizable Area End
}
